@import "core";
@import '@wearetla/tla-essentials-tools/partials/collapser/collapser-mixins';

@include collapser() {
	.collapser-btn {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 1.5rem 0;
		line-height: 2.3em;
		border-bottom: .1rem solid $color-gray-light;
		
		&:after {
			font-size: 1rem;
			transition: transform $transition-hover ease;
			@include icon('angle-right');
		}
	}

		.btn-text {
			@include font-medium;
			font-size: 1.6rem;
			padding-right: 1rem;
		}

	.collapser-innerwrap {
		color: $color-text-light;
		padding: 0 0 1.3rem;
		margin-top: -.2rem;
		font-size: 1.2rem;
		line-height: 1.8em;
	}

	&.active.show {
		.collapser-btn {
			&:after {
				transform: rotate(90deg);
			}
		}
	}
}