@import "core";
.page-faq {
	@include mediaMin {
		background-color: $color-bg;
	}

	// .faq-content {}

		.content-item {
			+ .content-item {
				border-top: .1rem solid $color-border;
			}
		}
}